import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  height: 12rem;

  box-shadow: 0px 15px 5px rgba(0, 0, 0, 0.1), 10px 20px 5px rgba(0, 0, 0, 0.05),
    -10px 20px 5px rgba(0, 0, 0, 0.05);

  @media only screen and (min-width: 480px) {
    height: 20rem;
  }

  @media only screen and (min-width: 768px) {
    height: 30rem;
  }

  @media only screen and (min-width: 1200px) {
    height: 40rem;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  aspect-ratio: auto;
  object-fit: cover;
`;

import React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Typography } from 'amphitheatre';
import { Fade } from 'react-slideshow-image';

import * as Styled from '../pages-styles/shared.styled';
import {
  BackgroundWrapper,
  BLCircle,
  TLCircle,
  BRBigCircle,
  BRSmallCircle,
} from '../components/Background';
import Layout from '../components/Layout';
import 'react-slideshow-image/dist/styles.css';

import SlideshowImage from '../components/SlideshowImage';
import {
  Slideshow,
  SlideshowSlide,
} from '../pages-styles/memberservices.styled';

import slideshowImage1 from '../images/member-services/img-1.jpeg';
import slideshowImage2 from '../images/member-services/img-2.jpeg';
import slideshowImage3 from '../images/member-services/img-3.jpeg';
import slideshowImage4 from '../images/member-services/img-4.jpeg';
import slideshowImage5 from '../images/member-services/img-5.jpeg';
import slideshowImage6 from '../images/member-services/img-6.jpeg';

const { Header, Paragraph } = Typography;
const MemberServices: React.FunctionComponent = () => {
  const indicators = () => <div className="indicator">•</div>;

  return (
    <Layout>
      <Helmet>
        <title>Wolfpack - Member Services</title>
      </Helmet>
      <BackgroundWrapper>
        <BLCircle />
        <TLCircle />
        <BRBigCircle />
        <BRSmallCircle />
      </BackgroundWrapper>
      <Styled.Container>
        <Header className="page-header">MEMBER SERVICES</Header>

        <Paragraph style={{ lineHeight: '1.5rem' }}>
          The Wolfpack Space Hub offers training programs, advice, technical
          support and pathways to success. 
        </Paragraph>

        <Paragraph style={{ lineHeight: '1.5rem' }}>
          Specifically, but not limited to:
          <ul>
            <li>
              Space specific support and training for space companies, not found
              in traditional incubators: 
            </li>
            <ul>
              <li>Deep-tech support for members to achieve flight. </li>
              <li>
                Operations support enabled through the Saber Astronautics’
                Responsive Space Operations Centre (RSOC) in Adelaide.
              </li>
              <li>
                Building technological readiness, offering full access to our
                fully equipped Rapid Prototyping Centre for satellites and space
                robotics
              </li>
            </ul>
          </ul>
          <ul>
            <li>
              Conventional support to startups such as advisory services, space
              specific leadership, business skills, business seminars/webinars: 
            </li>
            <ul>
              <li>
                Sales, marketing, commercialisation and leadership seminars for
                startups. 
              </li>
              <li>Grant application support.</li>
              <li>One-on-one weekly advisory meetings.</li>
              <li>Fortnightly Hub-wide collaborative meetings.</li>
              <li>
                Preparation for competitions such as the Waratah Seed
                Qualification Mission, Goldfish Tank etc.
              </li>
              <li>Preparation for investors pitch days.</li>
            </ul>
          </ul>
          <ul>
            <li>Industry relationships: AU, NZ, USA, Europe and Asia:</li>
            <ul>
              <li>
                Saber Astronautics' and TCG Group's global industry relations.
              </li>
              <li>A path to USSF through our Catalyst Campus collaboration.</li>
              <li>
                A path to export your space technologies in the US defence and
                commercial space market via our 'Going Global' Program.
              </li>
              <li>Establishing ongoing relationships with investors.</li>
              <li>
                Networking events including Space Oz, Parliament Pitch Day,
                Defence Pitch Day and others.
              </li>
              <li>
                Active collaboration 'as a pack' for grants and customer
                acquisition resulting in real growth.
              </li>
              <li>
                Warranted revenue through grants and contracts with customers
                within a year of membership in the Hub.
              </li>
              <li>Upcoming investment fund opportunities.</li>
            </ul>
          </ul>
          Please contact us if you could benefit from our services: 
          <Styled.EmailLink href="mailto:info@wolfpackspacehub.com">
            info@wolfpackspacehub.com
          </Styled.EmailLink>
        </Paragraph>
        <div style={{ margin: '2rem' }}>
          <Slideshow>
            <Fade indicators={indicators}>
              <SlideshowSlide>
                <SlideshowImage src={slideshowImage1} />
              </SlideshowSlide>
              <SlideshowSlide>
                <SlideshowImage src={slideshowImage2} />
              </SlideshowSlide>
              <SlideshowSlide>
                <SlideshowImage src={slideshowImage3} />
              </SlideshowSlide>
              <SlideshowSlide>
                <SlideshowImage src={slideshowImage4} />
              </SlideshowSlide>
              <SlideshowSlide>
                <SlideshowImage src={slideshowImage5} />
              </SlideshowSlide>
              <SlideshowSlide>
                <SlideshowImage src={slideshowImage6} />
              </SlideshowSlide>
            </Fade>
          </Slideshow>
        </div>
      </Styled.Container>
    </Layout>
  );
};

export default MemberServices;

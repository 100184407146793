import styled from 'styled-components';

export const SlideshowSlide = styled.div`
    .each-slide-effect > div {        
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        height: 100%;
    }
  
  .each-slide-effect span {
        padding: 20px;
        font-size: 20px;
        background: #efefef;
        text-align: center;
    }
}
`;

export const Slideshow = styled.div`
    .react-slideshow-container + ul.indicators {
        margin-top: 0.2rem;
        justify-content: center;
    }

    .indicators {
        margin: 0px;
        padding: 0px;
    }

    .indicator {
        color: #666;
        opacity: 0.5;
        display: flex;
        font-size: 2.5rem;
        cursor: pointer;
        padding: 0rem 0.2rem 0rem 0.2rem;
    }
      
    .indicator.active {
        color: #fff;
        opacity: 1;
    }
}
`;
